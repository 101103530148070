<template>
  <v-row>
    <v-col col="12" md="6">
      <v-card>
        <v-card-title> subscription remaining reports </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ remaining ? remaining.keyword_service : '' }} Keyword Research
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> {{ remaining ? remaining.serp_service : '' }} SERP Reports </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ remaining ? remaining.semantic_service : '' }} Semantic Reports
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> {{ remaining ? remaining.full_service : '' }} Full Report </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col col="12" md="6">
      <v-card>
        <v-card-title> Remaining reports purchased </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> {{ remaining ? remaining.keyword : '' }} Keyword Research </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> {{ remaining ? remaining.serp : '' }} SERP Reports </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> {{ remaining ? remaining.semantic : '' }} Semantic Reports </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ remaining ? remaining.full : '' }} Full Report</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    remaining: Object,
    service: Object,
  },
  setup(props) {
    return {}
  },
}
</script>

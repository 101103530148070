<template>
  <v-dialog v-model="dialog" persistent max-width="650">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on"> Edit Counters </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5"> Remaining Reports </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <span class="text-bold">Subscription</span>
              <v-switch v-model="active" :label="`${active ? 'Active' : 'Inactive'}`" hide-details="auto" />
            </v-col>
            <v-col cols="12">
              <span class="text-bold">Pay as you go</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                v-model="keyword"
                label="Keyword"
                :rules="[validators.integerValidator]"
                hide-details="auto"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                v-model="serp"
                label="Serp"
                :rules="[validators.integerValidator]"
                hide-details="auto"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                v-model="semantic"
                label="Semantic"
                :rules="[validators.integerValidator]"
                hide-details="auto"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                v-model="full"
                label="Full"
                :rules="[validators.integerValidator]"
                hide-details="auto"
                outlined
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <span class="text-bold">Subscription</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                v-model="keyword_service"
                label="Keyword"
                :rules="[validators.integerValidator]"
                hide-details="auto"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                v-model="serp_service"
                label="Serp"
                :rules="[validators.integerValidator]"
                hide-details="auto"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                v-model="semantic_service"
                label="Semantic"
                :rules="[validators.integerValidator]"
                hide-details="auto"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                v-model="full_service"
                label="Full"
                :rules="[validators.integerValidator]"
                hide-details="auto"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-alert v-if="!!errors" class="mt-4" color="error" text>
          <p class="font-weight-semibold mb-1">Messages</p>
          <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
            {{ error }}
          </p>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false" :disabled="loading"> Close </v-btn>
        <v-btn color="green darken-1" outlined @click="submit">
          <span v-if="loading === false">Save</span>
          <span v-else>
            <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
            <span>Saving...</span>
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required, integerValidator } from '@core/utils/validation'
// store
import store from '@/store'

export default {
  props: {
    data: Object,
    organization: Object,
    subscription: Object,
    refresh: Function,
  },
  setup(props) {
    const loading = ref(false)
    const errors = ref(null)
    const dialog = ref(false)
    const form = ref(null)

    const active = ref(props.subscription ? Boolean(props.subscription.active) : false)

    const keyword = ref(props.data.keyword)
    const serp = ref(props.data.serp)
    const semantic = ref(props.data.semantic)
    const full = ref(props.data.full)

    const keyword_service = ref(props.data.keyword_service)
    const serp_service = ref(props.data.serp_service)
    const semantic_service = ref(props.data.semantic_service)
    const full_service = ref(props.data.full_service)

    // store methods
    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }
    const reload = async data => {
      return await props.refresh(data)
    }
    const submit = async () => {
      const check = form.value.validate()
      if (!check) {
        return
      }

      const data = {
        id: props.organization.id,
        keyword: keyword.value,
        serp: serp.value,
        semantic: semantic.value,
        full: full.value,
        keyword_service: keyword_service.value,
        serp_service: serp_service.value,
        semantic_service: semantic_service.value,
        full_service: full_service.value,
        subscription_active: active.value,
      }

      loading.value = true
      return await store
        .dispatch('remaining_report/save', data)
        .then(async resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              await setSnackbar({
                status: true,
                text: resp.data.message,
                color: 'primary',
              })

              errors.value = null
              dialog.value = false

              reload(resp.data.data)
            }
          }
        })
        .catch(error => {
          console.log(errors.value)
          if (error.response.data && error.response.data.status === 'error') {
            errors.value = error.response.data.errors
          } else {
            errors.value = null
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      errors,
      dialog,
      form,

      active,
      keyword,
      serp,
      semantic,
      full,

      keyword_service,
      serp_service,
      semantic_service,
      full_service,

      validators: {
        required,
        integerValidator,
      },

      reload,
      submit,
    }
  },
}
</script>

<style type="scoped">
.text-bold {
  font-weight: bold;
}
</style>

<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <view-loader />
  </v-container>

  <v-alert v-else-if="!!errors" class="mt-4" color="error" text>
    <p class="font-weight-semibold mb-1">Messages</p>
    <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
      {{ error }}
    </p>
  </v-alert>

  <div class="organization" v-else>
    <h2>Organization: {{ name }}</h2>

    <v-card>
      <v-card-text>
        <form ref="formSetting">
          <h3>Settings</h3>
          <v-switch
            v-model="billing_tracking"
            inset
            :label="billing_tracking ? `Billing Tracking` : `No Billing Tracking`"
          ></v-switch>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" class="btn-submit" @click="saveSettings" :disabled="loadingSetting">
          <span v-if="loadingSetting === false">Save</span>
          <span v-else>
            <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
            <span>Saving...</span>
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <div class="row-title-actions">
      <h3>Remaining Reports</h3>
      <dialog-remaining
        v-if="remaining"
        :organization="organization"
        :data="remaining"
        :subscription="subscription"
        :refresh="refreshRemaining"
      />
    </div>

    <remaining-report v-if="organization" :remaining="remaining" />

    <h3>Information:</h3>

    <v-card>
      <v-card-text>
        <v-form ref="formInfo" class="multi-col-validation mt-6">
          <v-row>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="name"
                outlined
                label="Organizaton Name"
                :rules="[validators.required]"
                placeholder="Organizaton Name"
                hide-details="auto"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="email"
                outlined
                label="Email"
                :rules="[validators.emailValidator]"
                placeholder="Email"
                hide-details="auto"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="website"
                outlined
                label="Website"
                placeholder="Website"
                hide-details="auto"
                :rules="[validators.urlValidator]"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field v-model="phone" outlined label="Phone" placeholder="Phone" hide-details="auto" />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field v-model="service" outlined label="Service" placeholder="Service" hide-details="auto" />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="address"
                outlined
                label="Address"
                :rules="[validators.required]"
                placeholder="Address"
                hide-details="auto"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field v-model="address2" outlined label="Address2" placeholder="Address2" hide-details="auto" />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="city"
                outlined
                label="City"
                :rules="[validators.required]"
                placeholder="City"
                hide-details="auto"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="state"
                outlined
                label="State"
                :rules="[validators.required]"
                placeholder="State"
                hide-details="auto"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="zip"
                outlined
                label="Zip"
                :rules="[validators.required, validators.integerValidator]"
                placeholder="Zip"
                hide-details="auto"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-select
                v-model="country"
                :items="countries"
                label="Select Country"
                item-text="name"
                item-value="code"
                outlined
                :rules="[validators.required]"
              />
            </v-col>
          </v-row>
        </v-form>

        <v-alert v-if="!!errorsInfo" class="mt-4" color="error" text>
          <p class="font-weight-semibold mb-1">Messages</p>
          <p v-for="(error, i) in errorsInfo" :key="i" class="text-sm mb-0">
            {{ error }}
          </p>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" class="btn-submit" @click="submit" :disabled="loadingInfo">
          <span v-if="loadingInfo === false">Save</span>
          <span v-else>
            <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
            <span>Saving...</span>
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { computed, onBeforeMount, meh, ref, unref, watch } from '@vue/composition-api'
import { required, emailValidator, urlValidator, integerValidator } from '@core/utils/validation'
import countries from '@/const/countries'
import { useRouter } from '@core/utils'
// store
import store from '@/store'
//components
import ViewLoader from '@/components/spinner/ViewLoader.vue'
import RemainingReport from './components/RemainingReport.vue'
import DialogRemaining from './components/DialogRemaining.vue'

export default {
  components: {
    ViewLoader,
    RemainingReport,
    DialogRemaining,
  },
  setup(props) {
    const { router, route } = useRouter()

    const loading = ref(false)
    const loadingSetting = ref(false)
    const loadingInfo = ref(false)

    //form
    const formInfo = ref(null)
    const formSetting = ref(null)

    //errors
    const errors = ref(null)
    const errorsSetting = ref(null)
    const errorsInfo = ref(null)

    // organization settings
    const billing_tracking = ref(false)

    // organization information
    const organization = ref(null)
    const remaining = ref(null)
    const subscription = ref(null)

    const name = ref('')
    const email = ref('')
    const website = ref('')
    const phone = ref('')
    const service = ref('')
    const address = ref('')
    const address2 = ref('')
    const city = ref('')
    const state = ref('')
    const zip = ref('')
    const country = ref('US')

    // store methods
    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }
    const getOrganization = async () => {
      loading.value = true

      const params = new URLSearchParams({
        client_id: route.value.params.client_id,
      }).toString()

      return await store
        .dispatch('client/getOrganization', params)
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              const data = resp.data.data.client

              organization.value = data
              remaining.value = data.remaining_report
              subscription.value = data.client_account_service

              name.value = data.name
              email.value = data.email
              website.value = data.website
              phone.value = data.phone
              service.value = data.service
              address.value = data.address
              address2.value = data.address2
              city.value = data.city
              state.value = data.state
              zip.value = data.zip
              country.value = data.country
              billing_tracking.value = data.billing_tracking == '1' ? true : false
            }
          }
        })
        .catch(error => {
          if (error.response.data && error.response.data.status === 'error') {
            errors.value = error.response.data.errors
          } else {
            errors.value = null
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    // custon methods
    const goToPath = path => {
      router.push({ path })
    }
    const goToRoute = (name, params = null) => {
      router.push({ name: name, params: params })
    }
    const submit = async () => {
      const check = formInfo.value.validate()
      if (!check) {
        return
      }

      const data = {
        client_id: organization.value.client_id,
        name: name.value,
        email: email.value,
        website: website.value,
        phone: phone.value,
        service: service.value,
        address: address.value,
        address2: address2.value,
        city: city.value,
        state: state.value,
        zip: zip.value,
        country: country.value,
      }

      loadingInfo.value = true
      return await store
        .dispatch('client/updateOrganization', data)
        .then(async resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              await store.dispatch('snackbar/set', {
                status: true,
                text: resp.data.message,
                color: 'primary',
              })

              errorsInfo.value = null
            }
          }
        })
        .catch(error => {
          errorsInfo.value = error.response.data.errors
          console.log(errors.value)
        })
        .finally(() => {
          loadingInfo.value = false
        })
    }
    const saveSettings = async () => {
      // const check = formSetting.value.validate()
      // if (!check) {
      //   return
      // }

      const data = {
        client_id: organization.value.client_id,
        billing_tracking: billing_tracking.value,
      }

      loadingSetting.value = true
      return await store
        .dispatch('client/updateSettings', data)
        .then(async resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              await store.dispatch('snackbar/set', {
                status: true,
                text: resp.data.message,
                color: 'primary',
              })

              errorsSetting.value = null
            }
          }
        })
        .catch(error => {
          errorsSetting.value = error.response.data.errors
          console.log(errors.value)
        })
        .finally(() => {
          loadingSetting.value = false
        })
    }
    const refreshRemaining = organization => {
      remaining.value.keyword = organization.keyword
      remaining.value.serp = organization.serp
      remaining.value.semantic = organization.semantic
      remaining.value.full = organization.full
      remaining.value.keyword_service = organization.keyword_service
      remaining.value.serp_service = organization.serp_service
      remaining.value.semantic_service = organization.semantic_service
      remaining.value.full_service = organization.full_service
    }

    //cycle of life
    onBeforeMount(async () => {
      await getOrganization()
    })

    return {
      loading,
      loadingSetting,
      loadingInfo,

      errors,
      errorsSetting,
      errorsInfo,

      formInfo,
      formSetting,

      organization,
      remaining,
      subscription,

      billing_tracking,

      name,
      email,
      website,
      phone,
      service,
      address,
      address2,
      city,
      state,
      zip,
      country,

      setSnackbar,
      goToPath,
      goToRoute,
      submit,
      saveSettings,
      getOrganization,
      refreshRemaining,

      countries,
      validators: {
        required,
        emailValidator,
        urlValidator,
        integerValidator,
      },
    }
  },
}
</script>

<style>
.organization {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.row-title-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
</style>
